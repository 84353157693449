@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.payment-drawer {
  &__title {
    margin-top: $f-girot-space-m;
    margin-bottom: $f-girot-space-m;
  }

  &__sub-title {
    margin-top: $f-girot-space-xxs;
    display: flex;
    align-items: center;
    gap: $f-girot-space-xs;
  }
}
