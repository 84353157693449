@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.insurance-provider-scheduling-tab {
  margin-top: $f-girot-space-xs;
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-xs;
}
