@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.rejected-invoices-filter-details {
  margin-top: $f-girot-space-xs;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: $f-girot-space-xs;
}
