@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.page-header {
  width: auto;
  margin-bottom: $f-girot-space-s;
  position: absolute;
  right: 0;
  display: flex;
  gap: $f-girot-space-xs;
  justify-content: flex-end;
  align-items: center;

  &__logout-btn {
    margin-left: auto;
  }
}
