@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin handled-invocies-table-row {
}

.handled-invocies-table-row {
  height: auto;
}
