// Do not edit directly
// Generated on Fri, 04 Mar 2022 09:23:12 GMT

$f-girot-neutral-neutral-900: #091e42;
$f-girot-neutral-neutral-800: #15294b;
$f-girot-neutral-neutral-700: #243757;
$f-girot-neutral-neutral-600: #354764;
$f-girot-neutral-neutral-500: #42526d;
$f-girot-neutral-neutral-400: #505f79;
$f-girot-neutral-neutral-300: #5d6b82;
$f-girot-neutral-neutral-200: #6b788e;
$f-girot-neutral-neutral-100: #7a8699;
$f-girot-neutral-neutral-90: #8993a4;
$f-girot-neutral-neutral-80: #98a1b0;
$f-girot-neutral-neutral-70: #a6aebb;
$f-girot-neutral-neutral-60: #b3b9c4;
$f-girot-neutral-neutral-50: #c2c7d0;
$f-girot-neutral-neutral-40: #dfe2e6;
$f-girot-neutral-neutral-30: #ebedf0;
$f-girot-neutral-neutral-20: #f5f6f7;
$f-girot-neutral-neutral-10: #fafbfb;
$f-girot-neutral-neutral-0: #ffffff;
$f-girot-green-green-700: #14504a;
$f-girot-green-green-600: #175c55;
$f-girot-green-green-500: #218379;
$f-girot-green-green-400: #479890;
$f-girot-green-green-300: #7eb7b1;
$f-girot-green-green-200: #a4ccc8;
$f-girot-green-green-100: #e9f3f2;
$f-girot-blue-blue-700: #15355d;
$f-girot-blue-blue-600: #183d6a;
$f-girot-blue-blue-400: #4874aa;
$f-girot-blue-blue-500: #225798;
$f-girot-blue-blue-300: #7f9ec3;
$f-girot-blue-blue-200: #a4bad5;
$f-girot-blue-blue-100: #e9eef5;
$f-girot-orange-orange-700: #9a6515;
$f-girot-orange-orange-600: #b17318;
$f-girot-orange-orange-500: #fda522;
$f-girot-orange-orange-400: #fdb448;
$f-girot-orange-orange-300: #fecb7f;
$f-girot-orange-orange-200: #fedaa4;
$f-girot-orange-orange-100: #fff6e9;
$f-girot-red-red-700: #981f2f;
$f-girot-red-red-600: #ae2336;
$f-girot-red-red-500: #f9324d;
$f-girot-red-red-400: #fa556b;
$f-girot-red-red-300: #fc8898;
$f-girot-red-red-200: #fdabb6;
$f-girot-red-red-100: #feebed;
$f-girot-font-families-ibm-plex-serif: IBM Plex Serif;
$f-girot-font-families-open-sans: Open Sans;
$f-girot-line-heights-0: 40;
$f-girot-line-heights-1: 36;
$f-girot-line-heights-2: 32;
$f-girot-line-heights-3: 28;
$f-girot-line-heights-4: 24;
$f-girot-line-heights-5: 20;
$f-girot-font-weights-ibm-plex-serif-0: Bold;
$f-girot-font-weights-open-sans-1: Bold;
$f-girot-font-weights-open-sans-2: Regular;
$f-girot-font-weights-open-sans-3: Italic;
$f-girot-font-weights-open-sans-4: Bold Italic;
$f-girot-font-size-0: 14px;
$f-girot-font-size-1: 16px;
$f-girot-font-size-2: 18px;
$f-girot-font-size-3: 20px;
$f-girot-font-size-4: 23px;
$f-girot-font-size-5: 26px;
$f-girot-font-size-6: 29px;
$f-girot-font-size-7: 32px;
$f-girot-letter-spacing-0: 0;
$f-girot-paragraph-spacing-0: 0;
$f-girot-headings-h1-840: [object Object];
$f-girot-headings-h1-360-839: [object Object];
$f-girot-headings-h1-0-359: [object Object];
$f-girot-headings-h2: [object Object];
$f-girot-headings-h3: [object Object];
$f-girot-headings-h4: [object Object];
$f-girot-headings-subtitle1: [object Object];
$f-girot-body1-regular: [object Object];
$f-girot-body1-underline: [object Object];
$f-girot-body1-bold: [object Object];
$f-girot-body1-itallic: [object Object];
$f-girot-body1-bold-itallic: [object Object];
$f-girot-caption-regular: [object Object];
$f-girot-caption-bold: [object Object];
$f-girot-caption-itallic: [object Object];
$f-girot-caption-bold-itallic: [object Object];
$f-girot-text-case-none: none;
$f-girot-text-decoration-none: none;
$f-girot-text-decoration-underline: underline;
$f-girot-space-xxs: 4px;
$f-girot-space-xs: 8px;
$f-girot-space-s: 16px;
$f-girot-space-m: 24px;
$f-girot-space-l: 32px;
$f-girot-space-xl: 40px;
$f-girot-space-xxl: 48px;
$f-girot-space-xxxl: 64px;
$f-girot-border-radius-xs: 2px;
$f-girot-border-radius-s: 4px;
$f-girot-border-radius-m: 8px;
$f-girot-border-radius-l: 16px;
