@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin module-card {
}

.module-card {
  @include flexbox;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 30rem;
  align-items: center;
  padding: $f-girot-space-s;
  border-radius: $f-girot-border-radius-m;
  background-color: $white;
  transition: all 0.15s ease-in-out;
  border: 2px solid transparent;

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
    border: 2px solid $f-girot-green-green-500;
  }

  &--icon-container {
    @include flexbox;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: $f-girot-space-s 0;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    background-color: $f-girot-green-green-100;

    &__icon {
      path,
      circle {
        stroke: $f-girot-green-green-500;
      }
    }
  }

  &__typography {
    margin-bottom: $f-girot-space-s;
  }

  &--children-container {
    @include flexbox;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    &--child {
      @include flexbox;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;
      min-width: 8rem;
      align-items: center;
      background-color: $f-girot-neutral-neutral-20;
      border-radius: $f-girot-border-radius-m;
      margin: $f-girot-space-xxs;
      padding: $f-girot-space-xxs;
      &--label {
        color: $f-girot-neutral-neutral-300;
      }
    }
  }
}
