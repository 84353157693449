@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin invoices-tab {
}

.invoices-tab {
  margin-top: $f-girot-space-l;

  &__table-buttons {
    margin-top: $f-girot-space-m;
    display: flex;
    justify-content: flex-end;
    gap: $f-girot-space-xs;
  }

  &__filter-button {
    display: flex;
    justify-content: right;
    position: fixed;
    bottom: $f-girot-space-xl;
    right: $f-girot-space-xl;
  }
  &__mark-button {
    position: absolute;
    margin-left: $f-girot-space-xs;
    margin-top: $f-girot-space-m;
  }
}
