@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.filter-button {
  font-size: $f-girot-font-size-0;

  & .button__icon {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
  }
}
