@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin templates-tab {
}

.templates-tab {
  margin-top: $f-girot-space-m;

  &__banner {
    margin-top: $f-girot-space-m;
  }

  &__add-template-modal-button {
    display: flex;
    justify-content: right;
    position: fixed;
    bottom: $f-girot-space-xl;
    right: $f-girot-space-xl;
  }
}
