@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin insurance-type-mappings-tab {
}

.insurance-type-mappings-tab {
  margin-top: $f-girot-space-m;

  &__search-input {
    margin-bottom: $f-girot-space-s;
  }
}
