@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin create-internal-group-modal {
}

.create-internal-group-modal {
  height: auto;
}
