@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin payments-tab {
}

.payments-tab {
  margin-top: $f-girot-space-l;

  &__buttons-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  &__filter-button {
    display: flex;
    justify-content: right;
    position: fixed;
    bottom: $f-girot-space-xl;
    right: $f-girot-space-xl;
  }
}
