@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.accounting-balance-tab {
  &__period-select {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: $f-girot-space-xs;
    margin-top: $f-girot-space-s;
    margin-bottom: $f-girot-space-xs;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xs;
    margin-right: $f-girot-space-xs;
  }
}
