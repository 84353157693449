@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin drawer-base {
}

.drawer-base {
  &__title-container {
    margin: $f-girot-space-m 0;
  }
}
