@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans-Regular'),
    url('fonts/OpenSans/OpenSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'OpenSansMedium';
  src: local('OpenSans-Medium'),
    url('fonts/OpenSans/OpenSans-Medium.woff') format('woff');
}
@font-face {
  font-family: 'OpenSansMediumItalic';
  src: local('OpenSans-MediumItalic'),
    url('fonts/OpenSans/OpenSans-MediumItalic.woff') format('woff');
}
@font-face {
  font-family: 'OpenSansBold';
  src: local('OpenSans-Bold'),
    url('fonts/OpenSans/OpenSans-Bold.woff') format('woff');
}
@font-face {
  font-family: 'OpenSansBoldItalic';
  src: local('OpenSans-BoldItalic'),
    url('fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'OpenSansExtraBold';
  src: local('OpenSans-ExtraBold'),
    url('fonts/OpenSans/OpenSans-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'OpenSansExtraBoldItalic';
  src: local('OpenSans-ExtraBoldItalic'),
    url('fonts/OpenSans/OpenSans-ExtraBoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'OpenSansLight';
  src: local('OpenSans-Light'),
    url('fonts/OpenSans/OpenSans-Light.woff') format('woff');
}
@font-face {
  font-family: 'OpenSansLightItalic';
  src: local('OpenSans-LightItalic'),
    url('fonts/OpenSans/OpenSans-LightItalic.woff') format('woff');
}
@font-face {
  font-family: 'OpenSansSemiBold';
  src: local('OpenSans-SemiBold'),
    url('fonts/OpenSans/OpenSans-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'OpenSansSemiBoldItalic';
  src: local('OpenSans-SemiBoldItalic'),
    url('fonts/OpenSans/OpenSans-SemiBoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'OpenSansItalic';
  src: local('OpenSans-Italic'),
    url('fonts/OpenSans/OpenSans-Italic.woff') format('woff');
}
