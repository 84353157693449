@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.td-full-name {
  display: flex;

  &__avatar {
    margin-right: $f-girot-space-xs;
  }
}
