@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.messages-tab {
	@include flexbox;
	flex-direction: column;
	gap: $f-girot-space-xs;

	&__rule-group {
		margin-top: $f-girot-space-xs;
		margin-bottom: $f-girot-space-m;
	}

	&__info-text {
		display: block;
		margin-bottom: $f-girot-space-s;
	}
}
