@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin filter-component {
}

.filter-component {
  @include flexbox;
  align-items: center;
  margin: $f-girot-space-l 0;

  &__search {
    width: auto !important;
    flex-grow: 2;
    margin-right: $f-girot-space-s;
  }

  &__dropdowns {
    @include flexbox;
  }

  &__dropdown {
    margin-right: $f-girot-space-s;
  }
}
