@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.page-base {
  padding: 0 2.5rem 2rem 2.5rem;

  &__header {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $f-girot-neutral-neutral-20;
    padding-top: 3rem;
    padding-bottom: 1rem;
    z-index: 10;
  }

  &__back-button {
    margin-bottom: $f-girot-space-xs;
  }

  &__breadcrumb-list {
    &__item {
      display: inline;
      &:after {
        padding: $f-girot-space-xs;
        content: '/';
        font-size: 10px;
        color: $f-girot-neutral-neutral-90;
      }

      &__typography {
        color: $f-girot-neutral-neutral-90;
        display: inline;
        &:hover {
          color: $black;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  &__subtitle {
    color: $f-girot-neutral-neutral-90;
    font-size: 12px;
  }
}
