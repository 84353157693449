@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.internal-users-drawer-add-user-to-group {
  &__title {
    margin-top: $f-girot-space-m;
    margin-bottom: $f-girot-space-xs;
  }
  &__sub-title {
    margin-bottom: $f-girot-space-m;
  }
  &__select {
    margin-bottom: $f-girot-space-m;
  }
  &__button {
    width:100%;
  }
  &__search-input {
    margin-bottom: $f-girot-space-s;
  }
}