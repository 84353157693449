@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin scroll-load-more {
}

.scroll-load-more {
  @include flexbox;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: $f-girot-space-s;
  background-color: $white;

  &__text {
    margin-top: $f-girot-space-xs;
    color: $f-girot-neutral-neutral-300;
  }
}
