@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin handled-invoices-tab {
}

.handled-invoices-tab {
  margin-top: $f-girot-space-l;

  &__filter-button {
    display: flex;
    justify-content: right;
    position: fixed;
    bottom: $f-girot-space-xl;
    right: $f-girot-space-xl;
  }

  &__search-container {
    display: flex;
    &__input {
      margin-right: $f-girot-space-xs;
    }
  }

  &__pagination {
    margin-top: $f-girot-space-xs;
  }
}
