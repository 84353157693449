@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin transaction-analysis-tab {
}

.transaction-analysis-tab {
  margin-top: $f-girot-space-l;

  &__banner {
    margin-top: $f-girot-space-xs;
  }
}
