@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.text-area {
  &__small {
    & .input-area {
      font-size: 14px;
    }
  }
}
