@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.max-character-limit {
    &__footer {
        @include flexbox;
        justify-content: right;
    }

    &__text {
        color: $f-girot-neutral-neutral-300;
    }
}
