@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

#environment-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 8rem;
  background-color: #F05039;
  color: #1F449C;
  z-index: 200;
  font-size: 1.8em;
  text-align: center;

  img {
    width: 20px;
  }

}
