@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.configuration-tab {
  width: 100%;

  &__create-message-configuration-button {
    display: flex;
    justify-content: right;
    position: fixed;
    bottom: $f-girot-space-xl;
    right: $f-girot-space-xl;
  }

  &__section {
    margin-top: $f-girot-space-xs;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
}
