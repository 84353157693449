@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.insurance-providers-tab {
  width: 100%;

  &__displayed-providers {
    margin-top: $f-girot-space-l;
  }

  &__search-provider {
    @include flexbox;
    width: 100%;
    align-items: center;
    margin-top: $f-girot-space-l;
    flex-grow: 2;
    margin-right: $f-girot-space-s;
  }
}
