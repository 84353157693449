@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.permission {
  margin-top: $f-girot-space-xl;
  display:flex;
  justify-content: space-between;
  width: 100%;
  gap:$f-girot-space-xl;
  &__module-card-box-wrapper{
    display: flex;
    width: 100%;
    gap:10px;
  }
}