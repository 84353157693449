@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin loading-page {
}

.loading-page {
  background-color: $f-girot-neutral-neutral-10;
  @include flexbox;
  justify-content: center;
  align-items: center;
  &__text {
    color: $f-girot-neutral-neutral-300;
    margin-top: $f-girot-space-m;
  }
  &__details {
    color: $f-girot-neutral-neutral-300;
    margin-top: $f-girot-space-m;
  }
}
