@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin content-slot {
  @include flexbox;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.5rem;

  background: $f-girot-neutral-neutral-30;
  border-radius: $f-girot-space-xs;

  align-self: stretch;
  flex-grow: 1;
}

.content-slot {
  @include content-slot;
}
