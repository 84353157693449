@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin filter {
}

.customers {
  width: 100%;

  &__create-customer-button {
    display: flex;
    justify-content: right;
    position: fixed;
    bottom: $f-girot-space-xl;
    right: $f-girot-space-xl;
  }
}

.page-base__children {
  font-family: OpenSans, sans-serif;
}

.input__input--icon-left {
  background-color: #ebedf0;
  width: 432px;
}

.page-base__children header {
  padding: 0;
  background-color: transparent;
}
