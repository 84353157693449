@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.module-card-box {
  @include flexbox;
  box-sizing: border-box;
  flex-direction: column;
  gap: $f-girot-space-xxs;
  align-items: center;
  background-color: $f-girot-neutral-neutral-20;
  border-radius: $f-girot-border-radius-m;
  padding: $f-girot-space-xs $f-girot-space-s;
  width: 100%;
  cursor: pointer;

  &--label {
    color: $f-girot-neutral-neutral-300;
  }
}
