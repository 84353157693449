@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.groups-drawer-customers-tab {
  margin-top: $f-girot-space-m;
  &__navigation {
    margin-bottom: $f-girot-space-m;
  }
}
