@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.permission-module-card-box {
  background-color: $f-girot-neutral-neutral-30;
  padding: $f-girot-space-xs;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  &__title {
    color: $f-girot-neutral-neutral-300;
  }
  &__-subTitle {
    color: $f-girot-neutral-neutral-700;
  }
}
