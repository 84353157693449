@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin drawer-breadcrumbs {
}

.drawer-breadcrumbs {
  height: auto;
  &__breadcrumb{
    color:$f-girot-neutral-neutral-200;
    margin-right: $f-girot-space-xs;
  }
}
