@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin report-table-container {
  margin-top: $f-girot-space-xs;
}

.scroll-base {
  &__container {
    @include report-table-container();

    &--overflow {
      @include report-table-container();
      position: relative;
      overflow-y: scroll;
    }
  }

  &--overflow {
    margin-right: 8px;
  }

  &__loader {
    @include flexbox;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: $f-girot-space-s;
    background-color: $white;
  }
}
