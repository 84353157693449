@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.insurance-provider-message-tab {
  margin-top: $f-girot-space-xs;
  display: flex;
  flex-direction: column;
  gap: $f-girot-space-xs;

  &__inline-edit-value {
    display: flex;
    align-items: center;

    & > div,
    & button {
      width: 100%;
    }
  }

  &__list-group {
    margin-bottom: $f-girot-space-s;
  }

  &__info-message {
    margin-top: -$f-girot-space-s;
  }
}
