@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.search-payments-filters-modal {
  &__filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $f-girot-space-s;

    &__row-container {
      display: flex;
      flex-direction: row;
      margin-top: $f-girot-space-s;
      margin-bottom: $f-girot-space-s;
      gap: $f-girot-space-m;
    }
  }

  &__icon {
    margin-right: $f-girot-space-xs;

    path {
      stroke: $f-girot-green-green-400;
    }
  }

  &__select {
    display: flex;
    flex-direction: column;
    gap: $f-girot-space-xxs;

    & button {
      min-width: 100%;
    }
  }

  &__date {
    margin-bottom: $f-girot-space-s;
  }

  &__date-time-interval {
    display: flex;
    flex-direction: row;
    gap: $f-girot-space-xs;
  }
}
