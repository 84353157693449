@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

.create-external-admin-user-modal {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $f-girot-space-s;
    margin-bottom: $f-girot-space-s;
  }
  
  &__identifier {
    margin-bottom: $f-girot-space-s;
  }

  &__reason {
    margin-bottom: $f-girot-space-s;
  }

  &__email {
    margin-bottom: $f-girot-space-s;
  }
  &__divider {
    margin-top: $f-girot-space-m;
    margin-bottom: $f-girot-space-s;
  }
  
  &__customer-select {
    width: 100%;
  }
}
