@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin payments-table {
}

.payments-table {
  margin-top: $f-girot-space-m;
}
