@import './src/styles/_variables.scss';
@import './src/styles/global-alias.scss';
@import './src/styles/mixins.scss';

@mixin insurance-products-table-row {
}

.insurance-products-table-row {
  height: auto;
}
